import ky, { AfterResponseHook, BeforeRequestHook, Options } from "ky";
import HourglassGlobals from "../helpers/globals";
import { getCookie } from "../helpers/util";
import { ISODateString } from "../types/date";
import { XSRFHeader, baseUrl, circuitUrl, fsReportUrl, schedulingUrl } from "./const";
import { getStoredNumber, storeNumber } from "../helpers/dateStored";

export type QueryDateRange = {
  fromDate?: ISODateString;
  toDate?: ISODateString;
};

export function circuitPath(path: string): string {
  return circuitUrl + path;
}

export function fsReportPath(path: string): string {
  return fsReportUrl + path;
}

export function schedulingPath(path: string): string {
  return schedulingUrl + path;
}

const storageKey401 = "api401";

const afterResponse: AfterResponseHook[] = [
  (_, __, response) => {
    if (response.status === 401) {
      const last401 = getStoredNumber(storageKey401);
      if (last401) {
        const now = new Date().getTime();
        if (now - last401 < 1000) {
          // if it's been less than 1 second since our last 401, just stop
          console.error("401 loop");
          return response;
        }
      }
      storeNumber(storageKey401, new Date().getTime());
      window.location.href = HourglassGlobals.AppBase;
    }
    return response;
  },
];

const beforeRequest: BeforeRequestHook[] = [
  (request) => {
    if (import.meta.env.VITE_DEV_USER && import.meta.env.VITE_DEV_PASSWORD) {
      request.headers.set(
        "Authorization",
        `Basic ${btoa(import.meta.env.VITE_DEV_USER + ":" + import.meta.env.VITE_DEV_PASSWORD)}`,
      );
    }
    request.headers.set(XSRFHeader, getCookie(XSRFHeader));
    return request;
  },
];

const apiOptions: Options = {
  prefixUrl: baseUrl,
  timeout: 20 * 1000,
};

export const apiNoRedirect = ky.create({ ...apiOptions, hooks: { beforeRequest } });
export const api = ky.create({ ...apiOptions, hooks: { beforeRequest, afterResponse } });
